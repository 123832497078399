import React, { useEffect, useState } from 'react';
import './Products.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';  
import Preloader from '../Preloader';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productList);
      setLoading(false);
    };

    fetchProducts();
  }, []);

  return (
    <div className="products-page">

        <div
          className="single-product-hero"
          style={{
            backgroundImage: `url('/images/prod.png')`
          }}
        >
          <div className="hero-overlay">
            <h1 className="hero-heading">Available Products</h1>
            <p className="hero-subheading"></p>
            <br />
            <br />
            <div className="back-link">
                <Link to="/">
                  <span>Home</span>
                </Link>
                <span>/</span>
                <span>poducts</span> 
            </div>
          </div>
        </div>
      <h1 className="an-title">Choose From varieties of Products</h1>

      {loading ? (
        <div className="loading"><Preloader/></div>
      ) : (
        <div className="products-grid">
          {products.map((product) => (
           <Link to={`/product/${product.id}`} className='overlay'>
            <div className="product-card animated-card" key={product.id}>
              <img src={product.imageUrl} alt={product.name} className="product-image" />

              <div className="det">
               <h2 className="product-name">{product.name}</h2>
               <span className="product-price">N{product.price}</span>
              </div>
              <button className="over">View Product</button>
             </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Products; 