import React from 'react'
import "./Home.css"
import Hero from '../components/Hero'
import Products from '../components/products/Products2.jsx'
import Counter from '../components/Counter.jsx'
import SEO from '../components/SEO.jsx'
import Testimony from '../components/Testimony.jsx'
import Detail1 from '../components/Detail1.jsx'
import HomeDiv from '../components/HomeDiv.jsx'
import ProductCarousel from '../components/products/ProductCarousel.jsx'
import Products3 from '../components/products/Products3.jsx'


const Home = () => {
  return (
    <div className='home'>
        <SEO/>
        <Hero/>
        <Products/>
        <Detail1/>
        <HomeDiv/>
        <Counter/>
        <Testimony/>
        < ProductCarousel/>
        <Products3/>
      
    </div>
  )
}

export default Home
