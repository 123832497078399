// Preloader.js
import React, { useEffect, useState } from "react";
import "./Preloader.css";
import { useLocation } from "react-router-dom";

const Preloader = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    let counter = 0;
    const interval = setInterval(() => {
      counter += 1;
      setCount(counter);
      if (counter >= 100) {
        clearInterval(interval);
      }
    }, 30); // Adjust speed for a smoother effect

    return () => clearInterval(interval); // Cleanup
  }, [location.pathname]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Sync with the animation duration

    return () => clearTimeout(timer); // Cleanup
  }, [location.pathname]);

  if (loading) {
    return (
      <div className="loader-container" role="status" aria-live="polite">
        <div className="door door-left"></div>
        <div className="door door-right"></div>
        <div>
        <h1>Welcome to <br /> Tape Communications</h1>
        <div className="loader-text">{count}%</div>
        </div>
        
      </div>
    );
  }

  return children;
};

export default Preloader;
