import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(prev => !prev);

   // Function to close the dropdown when any link is clicked
   const handleLinkClick = () => {
    setIsOpen(false);  // Close the dropdown
  };

  

  return (
    <nav className="navbar">
      <div className="logo">
        <img src="/images/tape-logo.png" alt="" />
      </div>
      <div className={`nav-links ${isOpen ? "open" : ""}`}>

        {/* Blinking arrow pointing to toggle icon */}
        {!isOpen && <div className="arrow"></div>}

        <Link to="/" className="navs" onClick={handleLinkClick}>Home <span><IoIosArrowForward /></span></Link>
        <Link to="/Products" className="navs"  onClick={handleLinkClick}>Printings <span><IoIosArrowForward /></span></Link>
        <Link to="/about" className="navs" onClick={handleLinkClick}>About <span><IoIosArrowForward /></span></Link>
        <Link to="/contact" className="navs"  onClick={handleLinkClick}>Contact <span><IoIosArrowForward /></span></Link>
        <Link to="/services" className="navs"  onClick={handleLinkClick}>Services <span><IoIosArrowForward /></span></Link>
        <Link to="/News" className="navs"  onClick={handleLinkClick}>Our News <span><IoIosArrowForward /></span></Link>
        
        <Link to="/products3" className="navs" onClick={handleLinkClick}>Products3<span><IoIosArrowForward /></span></Link>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        {isOpen ? (
          <span className="close-icon">&times;</span>
        ) : (
          <span className="hamburger-icon">&#9776;</span>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
