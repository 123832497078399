import React, { useEffect, useState } from 'react';
import './Products3.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const Products3 = () => {
  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const querySnapshot = await getDocs(productsCollection);

        const productsByCategory = {};
        querySnapshot.forEach((doc) => {
          const product = doc.data();
          const category = product.category || 'Uncategorized';
          if (!productsByCategory[category]) {
            productsByCategory[category] = [];
          }
          productsByCategory[category].push({ id: doc.id, ...product });
        });

        setProducts(productsByCategory);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ppproducts-container">
      {Object.keys(products).map((category) => (
        <div key={category} className="category-section">
          <h2 className="category-title">{category}</h2>
          <div className="category-products">
            {products[category].map((product) => (
              <div key={product.id} className="ppproduct-card">
                <img src={product.imageUrl || '/placeholder.png'} alt={product.name} className="product-image" />
                <h3 className="ppproduct-name">{product.name}</h3>
                <p className="ppproduct-price">${product.price}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Products3;
