import React, { useEffect, useRef, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import "./ProductCarousel.css";
import { db } from "../../firebase";
import { Link } from "react-router-dom";

const ProductCarousel = () => {
  const [products, setProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerSlide = window.innerWidth > 768 ? 4 : 2;
  const carouselRef = useRef(null);

  // Fetch products from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const fetchedProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts([...fetchedProducts, ...fetchedProducts.slice(0, itemsPerSlide)]); // Include clones for seamless scroll
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, [itemsPerSlide]);

  const scrollNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const scrollPrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  useEffect(() => {
    const interval = setInterval(scrollNext, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentIndex === products.length - itemsPerSlide) {
        setCurrentIndex(0);
        carouselRef.current.style.transition = "none";
        carouselRef.current.style.transform = `translateX(0%)`;
        setTimeout(() => {
          carouselRef.current.style.transition = "transform 0.5s ease-in-out";
        }, 0);
      }
    };

    carouselRef.current?.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      carouselRef.current?.removeEventListener(
        "transitionend",
        handleTransitionEnd
      );
    };
  }, [currentIndex, products.length]);

  useEffect(() => {
    if (products.length) {
      carouselRef.current.style.transform = `translateX(-${
        (currentIndex * 100) / itemsPerSlide
      }%)`;
    }
  }, [currentIndex, itemsPerSlide, products]);

  // Check if the carousel has items to scroll
  const isAtStart = currentIndex === 0;
  const isAtEnd = currentIndex >= products.length - itemsPerSlide;

  return (
    <div className="carousel-container">
      <div className="carousel" ref={carouselRef}>
        {products.map((product, index) => (
          <div
            className="carousel-item"
            key={index}
            style={{ flex: `0 0 ${100 / itemsPerSlide}%` }}
          >
            <Link to={`/product/${product.id}`}>
              <img src={product.imageUrl} alt={product.name} />
            </Link>
            <p>{product.name}</p>
            <p className="price">N{product.price}</p>
          </div>
        ))}
      </div>
      {/* Conditionally render prev button */}
      {!isAtStart && (
        <button
          className="carousel-btn prev"
          onClick={scrollPrev}
        >
          ❮
        </button>
      )}
      {/* Conditionally render next button */}
      {!isAtEnd && (
        <button
          className="carousel-btn next"
          onClick={scrollNext}
        >
          ❯
        </button>
      )}
    </div>
  );
};

export default ProductCarousel;
