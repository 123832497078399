import React, { useState, useEffect, useRef } from 'react';
import './Testimony.css';
import { BiSolidQuoteAltRight } from 'react-icons/bi';

const testimonies = [
  {
    id: 1,
    text: "Tape Communication Nigeria Limited delivered exceptional printing services. The quality was outstanding and beyond our expectations.",
    name: "John Doe",
    country: "USA",
    image: "/images/cocacola.png",
  },
  {
    id: 2,
    text: "I was impressed by the professionalism and timely delivery. Their prints were crisp and vibrant. Highly recommended!",
    name: "Sarah Smith",
    country: "Canada",
    image: "/images/eko.png",
  },
  {
    id: 3,
    text: "Their customer service is top-notch. I received guidance on my project, and the final prints were amazing.",
    name: "Michael Lee",
    country: "UK",
    image: "/images/globus.png",
  },
  {
    id: 4,
    text: "I had a last-minute printing job, and Tape Communication Nigeria Limited delivered perfectly on time. Grateful for their efficiency!",
    name: "Emily Zhang",
    country: "China",
    image: "/images/grand-oak.png",
  },
  {
    id: 5,
    text: "The prints were high-quality, and the team was very professional. My project came out perfect. Thank you!",
    name: "Aisha Bello",
    country: "Nigeria",
    image: "/images/polaris.png",
  },
  {
    id: 6,
    text: "Tape Communication Nigeria Limited helped me with my business branding. Their design and printing services were exceptional!",
    name: "Ahmed Farouk",
    country: "Egypt",
    image: "/images/sterling.png",
  },
  {
    id: 7,
    text: "I couldn't have asked for a better printing experience. Tape Communication Nigeria Limited exceeded all my expectations.",
    name: "Laura Wilson",
    country: "Australia",
    image: "/images/toyota.png",
  },
  {
    id: 8,
    text: "Fantastic service! The prints were sharp and delivered within the promised time. I’ll definitely return for future projects.",
    name: "Luis Gomez",
    country: "Spain",
    image: "/images/toyota.png",
  },
  {
    id: 9,
    text: "The attention to detail and quality of service from Tape Communication Nigeria Limited is unmatched. Highly impressed!",
    name: "David Kim",
    country: "UAE",
    image: "/images/shop-global.png",
  },
  {
    id: 10,
    text: "Tape Communication Nigeria Limited made my printing job stress-free and seamless. The prints were flawless!",
    name: "Priya Sharma",
    country: "India",
    image: "/images/toyota.png",
  },
  {
    id: 11,
    text: "The team was amazing. They provided constant updates and delivered high-quality prints for my project.",
    name: "Samantha Jones",
    country: "Nigeria",
    image: "/images/eko.png",
  },
  {
    id: 12,
    text: "The printing process was smooth, and the final product was exactly as I envisioned. Great job, Tape Communication Nigeria Limited!",
    name: "Daniel Martinez",
    country: "Ghana",
    image: "/images/shop-global.png",
  },
  {
    id: 13,
    text: "Incredible printing services! Tape Communication Nigeria Limited was helpful and delivered top-quality prints.",
    name: "Raphael Peter",
    country: "Nigeria",
    image: "/images/mtn.png",
  },
  {
    id: 14,
    text: "I had a wonderful experience. The prints were stunning, and the customer service was exceptional. Thank you!",
    name: "Olivia Brown",
    country: "USA",
    image: "/images/abbey-bank.png",
  },
  {
    id: 15,
    text: "Tape Communication Nigeria Limited offers the best printing service! My order was handled professionally and promptly.",
    name: "James Olatokunbo",
    country: "Nigeria",
    image: "/images/globus.png",
  },
  {
    id: 16,
    text: "I can’t thank this team enough for their dedication. My prints were perfect, and the process was stress-free.",
    name: "Sofia Mitchel",
    country: "Nigeria",
    image: "/images/med-view.png",
  },
  {
    id: 17,
    text: "Amazing service! The quality and professionalism of Tape Communication Nigeria Limited are second to none.",
    name: "Victor Shon",
    country: "South Africa",
    image: "/images/toyota.png",
  },
  {
    id: 18,
    text: "Tape Communication Nigeria Limited made the printing process effortless. Their quality and service are top-tier.",
    name: "Emily Taylor",
    country: "UK",
    image: "/images/waltersmith.png",
  },
];


const Testimony = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStart = useRef(0); // To store touch start position
  const containerRef = useRef(null); // Reference to the container for scrolling

  // Automatically change testimony every 6 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonies.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonies.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonies.length) % testimonies.length);
  };

  // Handle swipe (touch) events
  const handleTouchStart = (e) => {
    touchStart.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    const touchDifference = touchStart.current - touchEnd;

    if (touchDifference > 50) {
      // Swiped left (next)
      handleNext();
    } else if (touchDifference < -50) {
      // Swiped right (previous)
      handlePrev();
    }
  };

  // Handle smooth scrolling by directly manipulating scroll position
  useEffect(() => {
    if (containerRef.current) {
      const scrollDistance = containerRef.current.scrollWidth / testimonies.length;
      containerRef.current.scrollTo({
        left: currentIndex * scrollDistance,
        behavior: 'smooth',
      });
    }
  }, [currentIndex]);

  return (
    <section className="testimony-container">
      <div className="content-box" data-aos="zoom-in">
        <p className="abbout-title">// WHAT OUR CLIENTS SAY //</p>
        <h2 className="abbout-heading">
        Let’s Explore What People Say About Us
        </h2>
        <p className='desc'>Our client love us and alway get impress by our decisive services.</p>

      </div>
      <div className="testimony-nav">
        <button className="scroll-button" onClick={handlePrev}>❮</button>
        <button className="scroll-button" onClick={handleNext}>❯</button>
      </div>
      <div
        className="testimony-cards"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        ref={containerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          scrollBehavior: 'smooth',
        }}
      >
        {testimonies.map((testimony, index) => (
          <div
            key={testimony.id}
            className="testimony-card"
            style={{
              flex: 'none',
              width: '100%',
              scrollSnapAlign: 'center',
              transition: 'transform 0.5s ease',
            }}
          >
            <div className="img-container">
            <div className="testimony-image" > 
             <img src={testimony.image} alt="" />
             <span><BiSolidQuoteAltRight /></span>
            </div>
            <p className="testimony-name">
              <strong>{testimony.name}</strong> <br /> <p className='test-p'>{testimony.country} </p>
            </p>
            </div>
            <p className="testimony-text">{testimony.text}</p>
          </div>
        ))}
      </div>
      

    </section>
  );
};

export default Testimony;
