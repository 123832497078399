import React, { useState, useEffect } from 'react';
import './Chat.css';
import { collection, addDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch messages from Firestore when the component loads
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'chatMessages'), (snapshot) => {
      const messagesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesList);
    });

    return () => unsubscribe();
  }, []);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      try {
        // Add the new message to Firestore
        await addDoc(collection(db, 'chatMessages'), {
          text: newMessage,
          sender: 'user',
          timestamp: new Date(),
        });
        
        // Set success message and clear input field
        setSuccessMessage('Message sent successfully!');
        setNewMessage('');

        // Hide the success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      } catch (error) {
        console.error("Error sending message: ", error);
      }
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h2>Chat with Us</h2>
      </div>
      <div className="chat-body">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`chat-message ${
              message.sender === 'admin' ? 'admin-message' : 'user-message'
            }`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <form className="chat-footer" onSubmit={handleSendMessage}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
          className="chat-input"
          required
        />
        <button type="submit" className="chat-send-btn">
          Send
        </button>
      </form>

      {/* Display success message after sending */}
      {successMessage && <div className="success-alert">{successMessage}</div>}
    </div>
  );
};

export default Chat;
