import react from "react";
import "./HomeDiv.css"
import { useNavigate } from "react-router-dom";

const HomeDiv =()=>{
 const navigate = useNavigate();
 const goTo =()=>{
    navigate("/products");
 }

    const hom =[
        {
            img: "/images/bus1.png",
        },
        {
            img: "/images/print.png",
        },
        {
            img: "/images/bus2.png",
        },
        // {
        //     img:'https://i.pinimg.com/1200x/44/40/46/44404611fe21c5d5ae7fc3b7c7669dad.jpg'
        // }
        // ,

        // {
        //     img:"https://i.pinimg.com/1200x/44/80/31/448031d9edca402fdd308efc8b66d4c2.jpg"
        // }
    ]

    return(
        <div>
            <div className="home-div">
              {hom.map((h, index)=>(
               <img src={h.img} alt={h.img} key={index} onClick={goTo}/>
              ))}
           </div>
       
        </div>
    )
};
export default HomeDiv;