import React from "react";
import "./Hero.css"; 
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="he-container"> 
      <div className="he-overlay">
        <div className="he-content">
          <h1 className="he-title">Welcome to <br />Tape Communications Nigeria Limited</h1>
          <p className="he-subtitle">Your One-Stop Solution for All Printing Needs</p>

          <Link to={"tel:+234-805-780-7496"} className="he-button">
             Call Us
          </Link>

          <Link to="/get-started" className="he-btn">
             Get Started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
