import React from 'react';
import './Contact.css';
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaMapMarkedAlt, FaPhone, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <div className="contact-page">

      <div
        className="single-product-hero"
        style={{
          backgroundImage: `url('/images/aa.png')`
        }}
      >
        <div className="hero-overlay">
          <h1 className="hero-heading">Contact Us</h1>
          <br />
          <div className="back-link">
            <Link to="/">
              <span>Home</span>
            </Link>
            <span>/</span>
            <span>Contact Us</span>
          </div>
        </div>
      </div>
      <div className="contact-header">
        <h1 className="animated-title">Get in Touch</h1>
        <p className="animated-subtitle">We would love to hear from you!</p>
      </div>

      <div className="contact-content">
        <div className="contact-box-map-and-input">
        <div className="contact-form-container">
          <form className="contact-form">
             <h2>Send Us Message</h2>
            <input type="text" placeholder="Enter your name" className="animated-input" required />

            <input type="email" placeholder="Enter your email" className="animated-input" required />

            <textarea placeholder="Write your message here..." className="animated-textarea" required></textarea>

            <button type="submit" className="submit-btn animated-btn">Send Message</button>
          </form>
        </div>

        <div className="contact-map">
          <h2>Find Us Here</h2>
          <iframe
            className="map"
            title="Our Location"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15852.449431569941!2d3.3468377!3d6.6329654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b93e7a105f355%3A0x6757298a0ee35736!2sTape%20Communications%20Nig.%20Ltd!5e0!3m2!1sen!2sus!4v1728830870507!5m2!1sen!2sus"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
        </div>
         
        <div className="contact-box-map-and-input">
        <div className="contact-details">
          <h3>Contact Information</h3>
          <p><FaPhone/> +234 805 780 7496, +2348062084431</p>
          <p><FaEnvelope /> info@tapecommunications.com</p>
          <p><FaMapMarkedAlt /> 8 Ijaiye Road, Ogba, Lagos, Nigeria</p>
        </div>

        <div className="working-hours">
          <h3>Working Hours</h3>
          <p>Monday - Friday: 9:00 AM - 5:00 PM</p>
          <p>Saturday: 10:00 AM - 3:00 PM</p>
          <p>Sunday: Closed</p>
        </div>

        <div className="social-media-links">
          <h3>Follow Us:</h3>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebook /> 
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter /> 
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram /> 
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <FaLinkedin /> 
            </a>
          </div>
        </div>

        {/* <div className="customer-support">
          <h3>Need Help?</h3>
          <Link to={"/chat"}>
          <button className="support-btn">Live Chat</button>
          </Link>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Contact;
