import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './SingleProduct.css';
import ProductCarousel from './ProductCarousel';
import Preloader from '../Preloader';

const SingleProduct = () => {
  const { productId } = useParams(); // Get the product ID from the URL
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      const productRef = doc(db, 'products', productId);
      const productSnap = await getDoc(productRef);
      if (productSnap.exists()) {
        setProduct({ id: productSnap.id, ...productSnap.data() });
      } else {
        console.log('No such product!');
      }
      setLoading(false);
    };

    fetchProduct();
  }, [productId]);

  return (
    <div className="single-product-page">
      {product && (
        <div
          className="single-product-hero"
          style={{
            backgroundImage: `url(${product.imageUrl})`
          }}
        >
          <div className="hero-overlay">
            <h1 className="hero-heading">{product.name}</h1>
            <span className="hero-subheading">
              {product.shortDescription}
            </span>
            <br />
            <br />
            <div className="back-link">
              <Link to="/">
                <span>Home</span>
              </Link>
              <span>/</span>
              <Link to="/products">
                <span>Products</span>
              </Link>
              <span>/</span>
              <span>{product.name}</span>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <Preloader />
      ) : product ? (
        <div className="single-div">
          <div className="single-p-img">
            <img src={product.imageUrl} alt={product.name} className="single-product-image" />
          </div>

          <div className="single-details">
            <h2>{product.name}</h2>
            <p>{product.fullDescription}</p>
            <p>
              <span>Price: N {product.price}</span>
            </p>

            {product.features && (
              <div className="product-features">
                <h3>Features:</h3>
                <ul>
                  {product.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            )}

            <div className="single-link">
              <Link to={"tel:+234-805-780-7496"} className="link-call">
                Call to order
              </Link>
              <Link to={"https://wa.me/2348057807496"} className="link-watsap">
                Whatsapp Chat
              </Link>
            </div>
          </div>
        </div>
        
      ) : (
        <div>Product not found.</div>
      )}

      <h1 className='choose'>Choose from the other Related Products</h1>
      <ProductCarousel />
    </div>
  );
};

export default SingleProduct;
