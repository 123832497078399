import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';

const teamMembers = [
  {
    name: 'Taiwo Adesina',
    role: 'CEO & Founder',
    description: 'Taiwo has over 30 years of experience in the printing and branding industry, leading the company to success with his visionary leadership.',
    image: '/images/ceo.png',
  },
  // Uncomment to add more team members
  // {
  //   name: 'John Doe',
  //   role: 'Chief Operating Officer',
  //   description: 'John ensures that all projects are delivered on time and to the highest standards.',
  //   image: 'https://via.placeholder.com/150',
  // },
  // {
  //   name: 'Jane Smith',
  //   role: 'Lead Designer',
  //   description: 'Jane leads the design team, making sure that every project is innovative and reflects the client\'s vision.',
  //   image: 'https://via.placeholder.com/150',
  // },
];

const companiesWorkedFor = [
  { image: '/images/mtn.png' },
  { image: '/images/cocacola.png' },
  { image: '/images/toyota.png' },
  { image: '/images/globus.png' },
  { image: '/images/eko.png' },
  { image: '/images/abbey-bank.png' },
  { image: '/images/sterling.png' },
  { image: '/images/grand-oak.png' },
  { image: '/images/med-view.png' },
  { image: '/images/blue-bird.png' },
  { image: '/images/shop-global.png' },
  { image: '/images/polaris.png' },
  { image: '/images/waltersmith.png' },
];

const valuesData = [
  {
    icon: '🌟', // You can replace these icons with any icons you prefer
    title: 'Innovation',
    description: 'We embrace new ideas to enhance our product offerings.',
  },
  {
    icon: '🤝',
    title: 'Integrity',
    description: 'We maintain honesty and transparency in all our dealings.',
  },
  {
    icon: '👥',
    title: 'People',
    description: 'We prioritize our team’s well-being and professional growth.',
  },
  {
    icon: '🏆',
    title: 'Quality',
    description: 'We deliver high-quality products that exceed customer expectations.',
  },
  {
    icon: '❤️',
    title: 'Passion',
    description: 'We are passionate about providing excellent service and products.',
  },
  {
    icon: '🌍',
    title: 'Friends of the Earth',
    description: 'We commit to sustainable practices that protect our environment.',
  },
];

const About = () => {
  return (
    <div className="about-page">
      <div
        className="single-product-hero"
        style={{
          backgroundImage: `url('/images/about.png')`
        }}
      >
        <div className="hero-overlay">
          <h1 className="hero-heading">About Us</h1>
          <br />
          <div className="back-link">
            <Link to="/">
              <span>Home</span>
            </Link>
            <span>/</span>
            <span>About Us</span>
          </div>
        </div>
      </div>
      
      {/* About Section */}
      
      <section className="about-section">
        <h2 className="title">About Us</h2>
        <p className="about-description">
          Tape Communications is a leading printing company providing a wide range of services including business card printing, branding, T-shirt printing, fabrications and more. Our mission is to deliver high-quality print solutions that exceed expectations.
        </p>
      </section>

      {/* Team Section */}
      <section className="team-section">
        <h2 className="title">The Founder Of Tape Communications</h2>
        <div className="team-container">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} className="team-member-image" />
              <h3 className="team-member-name">{member.name}</h3>
              <p className="team-member-role">{member.role}</p>
              <p className="team-member-description">{member.description}</p>
            </div>
          ))}
        </div>
      </section>

        {/* Vision and Mission Section */}
        <section className="vision-mission-section">
        <div className="about-print">
            <img src="/images/print.png" alt="" />
          </div>

        <div className="about-mis">
        <h2 className="title">Our Vision & Mission</h2>
        <div className='vision-mission'>
          <div className="vision-description">
             <h3 className="vision-title">Vision</h3>
             <p >
               To become one of the most sought after communication and brand advocacy firms in Africa.
             </p>
        </div>

        <div className="mission-description">
            <h3 className="mission-title">Mission</h3>
            <p >
               To cultivate a productive relationship with our clients by fulfilling our obligations to build equity for their brand.  
             </p>
         </div>
         </div>
        </div>
      </section>

      <div
        className="single-product-hero"
        style={{
          backgroundImage: `url('/images/aa.png')`
        }}
      >
        <div className="hero-overlay">
          <h1 className="hero-heading">Willing to Know More About Our Printing</h1>
          <br />
          <div className="back-link">
            <a href="tel:+234-805-7807-496">
              <span>Call Us</span>
            </a>
            <span>/</span>
            <a href="https://wa.me/2348057807496" target="_blank">
              Chat on WhatsApp
            </a>
          </div>
        </div>
      </div>

      {/* Values Section */}
      <section className="values-section">

        <h2 className="title">Our Values</h2>
        <div className="values-container">  
          {valuesData.map((value, index) => (
            <div key={index} className="value-item">
              <span className="value-icon">{value.icon}</span>
              <h4 className="value-title">{value.title}</h4>
              <p className="value-description">{value.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Companies Section */}
      <section className="companies-section">
        <h2 className="title">Companies We've Worked For</h2>
        <ul className="companies-list">
          {companiesWorkedFor.map((company, index) => (
            <li key={index} className="company-item">
              <img src={company.image} alt="" />
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default About;
