import React from 'react';
import './Detail1.css';  // Assuming you will add your styles in this file
import { FaRecycle, FaPrint, FaPalette, FaBoxOpen, FaImage, FaFileAlt, FaCogs, FaBoxes } from 'react-icons/fa';  // Importing icons

const Detail1 = () => {
  const services = [
    {
      title: "Recycled",
      description: "We offer high-quality recycled materials that are environmentally friendly and perfect for businesses looking to reduce their carbon footprint while maintaining excellent product quality.",
      icon: <FaRecycle />
    },
    {
      title: "CMYK",
      description: "At Tape Communications, we provide professional CMYK color printing, ensuring your designs come to life with vibrant, true-to-life colors.",
      icon: <FaPrint />
    },
    {
      title: "Branding",
      description: "Our branding services help businesses establish a unique and consistent identity that resonates with customers, from logos to business stationery.",
      icon: <FaPalette />
    },
    {
      title: "Printing",
      description: "We specialize in high-quality printing services, including brochures, flyers, and business cards, all designed to enhance your brand's visibility.",
      icon: <FaPrint />
    },
    {
      title: "Silk Screen",
      description: "Our silk screen printing techniques allow for high-quality, long-lasting prints on various materials, perfect for customized merchandise and corporate gifts.",
      icon: <FaImage />
    },
    {
      title: "Booklet",
      description: "We offer custom booklet printing services, providing businesses with professional, well-designed marketing materials that showcase products, services, and more.",
      icon: <FaFileAlt />
    },
    {
      title: "Design",
      description: "Our expert design team creates visually appealing and functional designs that convey your message effectively, whether for print or digital media.",
      icon: <FaCogs />
    },
    {
      title: "Packaging",
      description: "Tape Communications provides premium packaging solutions that are both functional and attractive, ensuring your products stand out on shelves.",
      icon: <FaBoxes />
    },
  ];

  return (
    <div className="sservices-container">
      <div className="sservices-list">
        {services.map((service, index) => (
          <div className="sservice-card" key={index}>
            <div className='head-icon'>
            <div className="sservice-icon">{service.icon}</div>
            <h2 className="sservice-title">{service.title}</h2>
            </div>
            <p className="service-desc">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Detail1;
