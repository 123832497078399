import React, { useEffect, useState } from 'react';
import './Products.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Preloader from '../Preloader';

const Products2 = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 6;

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productList);
      setLoading(false);
    };

    fetchProducts();
  }, []);

  // Logic for pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(products.length / productsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // SEO Meta Data
  const seoTitle = `Products - Page ${currentPage}`;
  const seoDescription = `Discover amazing products from our collection. Browse our page ${currentPage} of ${totalPages} for the best deals and options!`;
  const seoKeywords = "Products, E-commerce, General Printing, Printers in Nigeria, Nigeria Printers, Printers in Ogba, Printers in Lagos, Lagos Printers,  Online Shopping, Best Deals";

  return (
    <div className="products-page">
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/images/bus2.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content="/images/bus1.png" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Products",
            "description": seoDescription,
            "image": "/images/about.png",
          })}
        </script>
      </Helmet>

      <div
        className="single-product-hero"
        style={{
          backgroundImage: `url('/images/aa.png')`
        }}
      >
        <div className="hero-overlay">
          <h1 className="hero-heading">Available Products</h1>
          <br />
          <div className="back-link">
            <Link to="/">
              <span>Home</span>
            </Link>
            <span>/</span>
            <span>Products</span>
          </div>
        </div>
      </div>

      <h1 className="an-title">Choose From Varieties of Products</h1>

      {loading ? (
        <div className="loading"><Preloader /></div>
      ) : (
        <>
          <div className="products-grid">
            {currentProducts.map((product) => (
              <Link to={`/product/${product.id}`} className="overlay" key={product.id}>
                <div className="product-card animated-card">
                  <img src={product.imageUrl} alt={product.name} className="product-image" />
                  <div className="det">
                    <h2 className="product-name">{product.name}</h2>
                    <span className="product-price">N{product.price}</span>
                  </div>
                  <button className="over">View Product</button>
                </div>
              </Link>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="pagination">
            <button
              className="pagination-btn"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            {[...Array(totalPages).keys()].map(number => (
              <button
                key={number + 1}
                className={`pagination-btn ${currentPage === number + 1 ? 'active' : ''}`}
                onClick={() => paginate(number + 1)}
              >
                {number + 1}
              </button>
            ))}
            <button
              className="pagination-btn"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Products2;
